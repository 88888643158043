import { jwtDecode } from 'jwt-decode';

export default class CognitoUserSession {
  /**
   * Constructs a new CognitoUserSession object
   * @param {CognitoJwtToken} AccessToken The session's access token.
   */
  constructor({ AccessToken } = {}) {
    if (AccessToken === null) {
      throw new Error('Access Token must be present.');
    }

    this.accessToken = AccessToken;
  }

  /**
   * @returns {jwt} the session's access token
   */
  getAccessToken() {
    return this.accessToken;
  }

  /**
   * Checks to see if the session is still valid based on session expiry information found
   * in tokens and the current time (adjusted with clock drift)
   * @returns {boolean} if the session is still valid
   */
  isValid() {
    
    if(!this.accessToken)
      return false;

    const decodedToken = jwtDecode(this.accessToken.jwtToken);
    const exp = decodedToken.exp;
    const isTokenValid = Date.now() < exp * 1000;
    console.log("Is token valid: " + isTokenValid);
    return isTokenValid;
  }
}
